<template>
  <div class="banner-container">
    <!-- Fondo con gradiente -->
    <v-img
      src="../../../assets/images/home-cover.jpeg"
      alt="Descripción de la imagen"
      contain
    >
      <div class="overlay-gradient"></div>
      <div class="text-container">
        <div class="overlay">
          <!-- Título -->
          <h2 class="title">
            {{ texts.banner.titleSection }}
          </h2>
          <!-- Subtítulo -->
          <h3 class="subtitle">
            {{ texts.banner.subtitleSection }}
          </h3>
          <!-- Botón -->
          <div class="button-container">
            <v-btn
              to="/dashboard"
              text
              class="button-register"
            >
              {{ texts.banner.buttonBuyer }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeBannerLayout",
  data() {
    return {
      //VARIABLES INACTIVAS
      isResponsive: false, // PENDIENTE A REVISIÓN DE USO
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize); // PENDIENTE A REVISIÓN DE USO
    this.handleResize(); // PENDIENTE A REVISIÓN DE USO
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize); // PENDIENTE A REVISIÓN DE USO
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    }, // PENDIENTE A REVISIÓN DE USO
  },
};
</script>

<style scoped>
/* Definición de fuentes necesarias */
@font-face {
  font-family: 'RethinkSans';
  src: url('../../../assets/fonts/RethinkSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'RethinkSans';
  src: url('../../../assets/fonts/RethinkSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

/* Estilo del título */
.title {
  font-family: 'RethinkSans', sans-serif;
  font-weight: 700;
  font-size: 4vw !important;
  color: #f5f5f5;
  margin-bottom: 30px;
  padding-right: 50%;
  line-height: 1.1;
}

/* Estilo del subtítulo */
.subtitle {
  font-family: 'RethinkSans', sans-serif;
  font-weight: 400;
  font-size: 1.5vw;
  color: #f5f5f5;
  padding-right: 60%;
  margin-bottom: 50px;
}

/* Estilo del botón */
.button-register {
  width: 263px !important;
  height: 44px !important;
  background-color: #0075B8 !important;
  border-radius: 10px;
  padding: 10px 20px;
  color: #F5F5F5 !important;
  font-size: 16px !important;
  text-transform: initial;
}

/* Container del banner */
.banner-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Imagen de fondo con gradiente */
.overlay-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(13, 38, 72, 0.9) 20%, 
    rgba(13, 38, 72, 0.6) 40%, 
    rgba(0, 0, 0, 0.22) 70%
  );
  z-index: 1;
}

/* Contenedor del texto */
.text-container {
  position: relative;
  z-index: 2;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

/* Botón container para control adicional */
.button-container {
  margin-top: 30px;
}
</style>